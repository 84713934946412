export default {
	// SendBird
	sendBirdAppId: process.env.REACT_APP_SENDBIRD_APP_ID,

	// Google Cloud
	googleAuthApi: process.env.REACT_APP_GOOGLE_AUTH_API,
	googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,

	// App
	webUrl: process.env.REACT_APP_WEB_URL,
	apiUrl: process.env.REACT_APP_BASE_URL,
	defaultUserId: process.env.REACT_APP_DEFAULT_USER_ID,
	defaultUserName: process.env.REACT_APP_DEFAULT_USER_NAME,
	defaultDomainName: process.env.REACT_APP_DEFAULT_DOMAIN_NAME,
	defaultChannelId: process.env.REACT_APP_DEFAULT_CHANNEL_ID
};
