import { createContext, useCallback, useContext } from 'react';
import axios from 'axios';
import { UserSessionContext } from './UserSessionContext';
import env from '../config/env';

export const GoogleApiContext = createContext();

const AxiosService = axios.create({
	baseURL: env.googleAuthApi
});

const GoogleApiContextProvider = ({ children }) => {
	const { setShowLoader, setUserData } = useContext(UserSessionContext);

	const get = useCallback(
		async (endpoint, showLoader = true, header) => {
			setShowLoader(showLoader);
			return AxiosService.get(endpoint, header)
				.then(res => {
					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					console.log(`🛑 Google GET - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => {
					setShowLoader(false);
				});
		},
		[setShowLoader]
	);

	const getGoogleUser = useCallback(
		async data => {
			const res = await get(`/userinfo?access_token=${data.access_token}`, {
				header: {
					Authorization: `Bearer ${data.access_token}`,
					Accept: 'application/json'
				}
			});

			if (res.success) setUserData(res.response);

			return res;
		},
		[get, setUserData]
	);

	return <GoogleApiContext.Provider value={{ getGoogleUser }}>{children}</GoogleApiContext.Provider>;
};

export default GoogleApiContextProvider;
