export default {
	white: '#FFFFFF',
	black: '#000000',
	gray_200: '#BABABA',
	gray_500: '#667085',
	primary: '#1B1D21',
	borderColor: '#2C2C2C',
	hoverBgColor: '#1e293a',
	itemSelected: '#FEC92B',
	selectedNavColor: '#0F6EC7',
	navColor: '#162D42',
	faqCardBgColor: '#2E3033',
	faqCardBorder: '#ABABAD',
	chatProfileCardBg: '#596F4C',
	linkColor: '#192573',
	askAwayIconBg: '#D9D9D9',
	menuIconColor: '#3f51b5',
	lightRed: '#FF474C',
	selectedDomainBg: {
		light: '#C7CFFF33',
		dark: '#667085'
	},
	selectedDomainBorderColor: '#192573',
	switchColor: {
		light: '#333333',
		dark: '#FFFFFF'
	},
	talkToGpt: {
		light: '#EDEEF8',
		dark: '#1B1D21'
	},
	thumbUp: {
		light: '#8bc9cd',
		dark: '#FFFFFF80'
	},
	thumbDown: '#E88E3D',
	textInputBorderColor: {
		light: '#9EA6DC',
		dark: '#000B4F'
	},
	msgSendIcon: {
		light: '#FFFFFF',
		dark: '#000B4F'
	},
	topNavColor: {
		selected: {
			light: '#EDEEF8',
			dark: ''
		},
		default: {
			light: '#D7D7D7',
			dark: ''
		}
	},
	createdAtColor: '#666666',
	primaryDomainColor: '#1034A6',
	textColor: {
		light: '#3D3F51',
		dark: '#F7F7F8'
	},
	homeBg: {
		light: '#F6F8FA',
		dark: '#2F363D'
	}
};
