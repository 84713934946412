import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const ConfirmDialog = ({ open, onClose, message = 'Are you sure?', handleDeleteBtnPress }) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{message}</DialogTitle>

			<DialogActions>
				<Button onClick={onClose} sx={{ color: theme => theme.palette.globalIcon.main }}>
					Cancel
				</Button>

				<Button
					onClick={() => {
						onClose();
						handleDeleteBtnPress && handleDeleteBtnPress();
					}}
					sx={{ color: theme => theme.palette.globalIcon.main }}
					autoFocus
				>
					Delete All
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
