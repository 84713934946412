import { Parser } from 'html-to-react';

export const rawHtmlToReact = rawHtml => {
	return Parser().parse(rawHtml);
};

export const isObjectEmpty = obj => {
	if (!obj) return true;

	return Object.keys(obj).length === 0;
};

export const getMessageSendDate = dateToFormat => {
	// Parse the ISO 8601 timestamp
	const dt = new Date(dateToFormat);

	const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' };

	const formattedDate = dt.toLocaleString('en-US', options);

	return `${formattedDate}`;
};
