export const drawerWidth = '330px';

export const navButtons = [
	{
		id: 1,
		name: 'Talk To GPT'
	},
	{
		id: 2,
		name: 'Human Chats'
	}
	// {
	// 	id: 3,
	// 	name: 'Browse FAQs'
	// }
];

export const navButtonText = {
	humanChats: 'Human Chats',
	talkToGtp: 'Talk To GPT',
	browseFaq: 'Browse FAQs'
};

export const themeText = {
	light: 'light',
	dark: 'dark'
};
