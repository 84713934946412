import React from 'react';
import { BrowserRouter as Router, Routes as ApplicationRoutes, Route } from 'react-router-dom';

import Home from './screens/Home';
import PageNotFound from './screens/PageNotFound';

function Routes() {
	return (
		<Router>
			<ApplicationRoutes>
				<Route path="/app" element={<Home />} />

				<Route path="*" element={<PageNotFound />} />
			</ApplicationRoutes>
		</Router>
	);
}

export default Routes;
