import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import colors from '../asset/colors';
import googleLogo from '../asset/images/google_logo.png';

function GoogleLoginModal({ open, onClose, handleGoogleLogin, userName, setUserName }) {
	return (
		<Modal open={open} onClose={onClose}>
			<Box sx={styles.userNameModalContainer}>
				<TextField
					value={userName}
					onChange={setUserName}
					label="User name"
					variant="outlined"
					sx={{ width: '100%', mb: 4 }}
				/>

				<Button
					variant="contained"
					onClick={handleGoogleLogin}
					sx={styles.footerBtn}
					startIcon={<img src={googleLogo} width={30} height={30} />}
				>
					<Typography color={colors.black} fontSize="20px">
						Sign in with Google
					</Typography>
				</Button>
			</Box>
		</Modal>
	);
}

const styles = {
	userNameModalContainer: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		borderRadius: 5
	},
	footerBtn: {
		paddingY: '10px',
		paddingX: '30px',
		minHeight: 0,
		minWidth: 0,
		textTransform: 'none',
		borderRadius: 40,
		backgroundColor: colors.white,
		'&: hover': {
			bgcolor: colors.white
		},
		boxShadow: '0px 2px 25px -2px rgba(0, 0, 0, 0.15)',
		width: '100%'
	}
};

export default GoogleLoginModal;
