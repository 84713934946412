import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center' }}
		>
			<Typography fontSize={50}>Page Not Found</Typography>

			<Typography fontSize={30} my={3} textAlign={'center'}>
				The page you requested was not found!
			</Typography>

			<Button sx={{ paddingX: 10 }} onClick={() => navigate(-1, { replace: true })} variant="contained">
				Go Back
			</Button>
		</Box>
	);
}

export default PageNotFound;
