import { Box, Button, CircularProgress, IconButton, InputBase, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import colors from '../../asset/colors';
import { v4 as uuidv4 } from 'uuid';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { ApiContext } from '../../context/ApiContext';
import chatGPTIcon from '../../asset/images/chatgpt.svg';
import { UserSessionContext } from '../../context/UserSessionContext';
import { showErrorToast, showInfoToast } from '../../utils/toastUtils';
import { getMessageSendDate, isObjectEmpty } from '../../utils/utils';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { drawerWidth } from '../../asset/constants';
import { useSearchParams } from 'react-router-dom';
import GoogleLoginModal from '../GoogleLoginModal';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleApiContext } from '../../context/GoogleApiContext';
import { useCookies } from 'react-cookie';

const TalkToGpt = ({ messages = [], setMessages, getAllGPTMessages }) => {
	// eslint-disable-next-line no-unused-vars
	const [cookie, setCookie] = useCookies(['user']);

	const [searchParams, setSearchParams] = useSearchParams();
	const questionToAsk = searchParams.get('question');

	const chatContainerRef = useRef(null);
	const isScreenBig = useMediaQuery('(min-width:900px)');

	const { sendMessageToBard, updateMessage, getUserById, createUser, updateUserById } = useContext(ApiContext);
	const { getGoogleUser } = useContext(GoogleApiContext);

	const { selectedDomain, userData, isSplitViewEnabled, selectedAiPromptMessage, setSelectedAiPromptMessage } =
		useContext(UserSessionContext);

	const isUserLoggedIn = !isObjectEmpty(userData);

	const [messageText, setMessageText] = useState('');

	const [isProcessingQuery, setIsProcessingQuery] = useState(false);
	const [scrollScreenToBottom, setScrollScreenToBottom] = useState(true);

	const [isUserNameModalOpen, setIsUserNameModalOpen] = useState(false);
	const [userName, setUserName] = useState('');

	useEffect(() => {
		if (selectedAiPromptMessage) {
			setMessageText(selectedAiPromptMessage);
		}
	}, [selectedAiPromptMessage]);

	useEffect(() => {
		if (scrollScreenToBottom) {
			scrollChatContainerToBottom();
		}
	}, [messages, scrollScreenToBottom]);

	// This use effect will only work when there is a question in the query params http://localhost:3000/app/?domain=DOMAIN_NAME?channel=CHANNEL_ID&question=Tell+me+about+IIT+Jee
	useEffect(() => {
		if (questionToAsk && userData.user_id) {
			setIsProcessingQuery(true);

			const sendMessages = async () => {
				const { response } = await sendMessageToBard({
					question: questionToAsk,
					channel_id: selectedDomain?.selectedChannel?.channel_id,
					user_id: userData.user_id
				});

				setMessages(prev => [
					...prev,
					{ id: uuidv4(), sender: 'user', message: questionToAsk, created_at: new Date() }
				]);

				setMessages(prev => [
					...prev,
					{
						id: response.id,
						sender: 'GPT',
						message: response.answer || 'Something went wrong, please try again later.',
						question: response.question
					}
				]);

				setIsProcessingQuery(false);

				searchParams.delete('question');
				searchParams.delete('channel');
				searchParams.delete('domain');

				setSearchParams(searchParams);
			};

			sendMessages();
		}
	}, [
		questionToAsk,
		searchParams,
		selectedDomain?.selectedChannel?.channel_id,
		sendMessageToBard,
		setMessages,
		setSearchParams,
		userData.user_id
	]);

	const handelSendMessage = async () => {
		setSelectedAiPromptMessage(null);
		setIsProcessingQuery(true);
		setScrollScreenToBottom(true);
		setMessages(prev => [...prev, { id: uuidv4(), sender: 'user', message: messageText, created_at: new Date() }]);
		setMessageText('');

		const { response } = await sendMessageToBard({
			question: messageText,
			channel_id: selectedDomain?.selectedChannel?.channel_id,
			user_id: userData.user_id
		});

		setMessages(prev => [
			...prev,
			{
				id: response.id,
				sender: 'GPT',
				message: response.answer || 'Something went wrong, please try again later.',
				question: response.question
			}
		]);

		setIsProcessingQuery(false);
	};

	const scrollChatContainerToBottom = () => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	};

	const handleUpVoteDownVote = async (voteType, chat) => {
		setScrollScreenToBottom(false);

		const dataToUpdate = {
			vote: voteType === 'upvote' ? '1' : '-1'
		};

		const { success } = await updateMessage(chat.id, dataToUpdate);

		if (!success) {
			showErrorToast('Something went wrong please try again later');
			return;
		}

		getAllGPTMessages();
	};

	const copyToClipboard = msg => {
		navigator.clipboard.writeText(msg);
		showInfoToast('Copied to clipboard!');
	};

	const renderFeedbackButton = chat => {
		if (chat?.vote === '1') {
			return (
				<Stack direction={'row'} spacing={2} sx={styles.feedbackIcon}>
					<ThumbUpIcon sx={styles.thumbUpColor} />

					<IconButton sx={styles.renderFeedbackBtn} onClick={() => copyToClipboard(chat.message)}>
						<ContentPasteIcon sx={styles.copyPasteIcon} />
					</IconButton>
				</Stack>
			);
		} else if (chat?.vote === '-1') {
			return (
				<Stack direction={'row'} spacing={2} sx={styles.feedbackIcon}>
					<ThumbDownIcon sx={styles.thumbDownColor} />

					<IconButton sx={styles.renderFeedbackBtn} onClick={() => copyToClipboard(chat.message)}>
						<ContentPasteIcon sx={styles.copyPasteIcon} />
					</IconButton>
				</Stack>
			);
		}

		return (
			<Stack direction={'row'} spacing={2} sx={styles.feedbackIcon}>
				<IconButton sx={styles.renderFeedbackBtn} onClick={() => handleUpVoteDownVote('upvote', chat)}>
					<ThumbUpAltOutlinedIcon sx={styles.thumbUpColor} />
				</IconButton>

				<IconButton sx={styles.renderFeedbackBtn} onClick={() => handleUpVoteDownVote('downvote', chat)}>
					<ThumbDownAltOutlinedIcon sx={styles.thumbDownColor} />
				</IconButton>

				<IconButton sx={styles.renderFeedbackBtn} onClick={() => copyToClipboard(chat.message)}>
					<ContentPasteIcon sx={styles.copyPasteIcon} />
				</IconButton>
			</Stack>
		);
	};

	const renderChatMessages = () =>
		messages.map((chat, index) => {
			const isSenderChatGPT = chat.sender === 'GPT';

			return (
				<Stack
					key={chat.id}
					direction="row"
					alignItems={'center'}
					justifyContent={isSenderChatGPT ? 'flex-start' : 'flex-end'}
					spacing={2}
				>
					<img
						style={{
							...styles.senderIcon,
							marginLeft: isScreenBig ? 40 : 10,
							display: isSenderChatGPT ? 'block' : 'none'
						}}
						src={chatGPTIcon}
						alt={chat.sender}
					/>

					<Stack sx={[styles.messageBox]}>
						<Box
							sx={[
								styles.messageContainer,
								isSenderChatGPT
									? { borderTopRightRadius: 22, marginBottom: index === 0 ? 4 : 0 }
									: { borderTopLeftRadius: 22, marginBottom: 0 }
							]}
						>
							<Typography sx={{ whiteSpace: 'pre-wrap' }} variant="subtitle1">
								{chat.message}
							</Typography>
						</Box>

						{isSenderChatGPT && index !== 0 && renderFeedbackButton(chat)}

						{/* Show created_at only in User messages */}
						{!isSenderChatGPT && (
							<Typography color={'primary.talkToGpt.createdAtColor'} fontWeight={300} sx={styles.createdAt}>
								{getMessageSendDate(chat.created_at)}
							</Typography>
						)}
					</Stack>

					<img
						style={{
							...styles.senderIcon,
							marginRight: isScreenBig ? 40 : 10,
							display: isSenderChatGPT ? 'none' : 'block'
						}}
						src={userData.profile_picture_url}
						alt={chat.sender}
					/>
				</Stack>
			);
		});

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: async codeResponse => {
			setIsUserNameModalOpen(false);

			const { response: googleAuthResponse } = await getGoogleUser(codeResponse);

			// Check if user exist in BE or not
			const getUserRes = await getUserById(googleAuthResponse.id);

			if (!getUserRes.success) {
				// Create user in BE
				await createUser({
					user_id: googleAuthResponse.id,
					name: userName === '' ? googleAuthResponse.name : userName,
					email: googleAuthResponse.email,
					profile_picture_url: googleAuthResponse.picture
				});
			}

			if (getUserRes.success && userName && getUserRes.response?.name !== userName) {
				// Update the user name if entered user name does not match the user response username
				await updateUserById(googleAuthResponse.id, { name: userName });
			}

			setCookie('user', googleAuthResponse, {
				path: '/',
				expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
			});

			setUserName('');
		},
		onError: error => {
			setIsUserNameModalOpen(false);
			setUserName('');

			console.log('Login Failed:', error);
		}
	});

	return (
		<Box sx={styles.container} ref={chatContainerRef}>
			{isUserLoggedIn ? (
				<>
					<Box sx={styles.chatContainer} pt={5}>
						{renderChatMessages()}

						<Box
							component={'div'}
							sx={[
								styles.inputContainer,

								{ width: isSplitViewEnabled ? '50%' : { xs: '100%', md: `calc(100% - ${drawerWidth})` } }
							]}
						>
							<InputBase
								multiline
								placeholder={isProcessingQuery ? 'Processing...' : 'Write your message...'}
								value={messageText}
								onChange={e => setMessageText(e.target.value)}
								sx={[styles.inputBox, { fontSize: '13px' }]}
								maxRows={4}
								disabled={isProcessingQuery}
								onKeyDown={event => {
									// shift + enter for scroll
									if (event.shiftKey && event.key === 'Enter') {
										return;
									}

									// only Enter key for sending message
									if (event.key === 'Enter' && messageText.trimStart().length) {
										handelSendMessage();
									}
								}}
							/>

							<IconButton
								sx={styles.inputBtn}
								onClick={() => {
									if (messageText.length) {
										handelSendMessage();
									}
								}}
							>
								{isProcessingQuery ? (
									<CircularProgress
										size={'18px'}
										sx={{ color: theme => theme.palette.primary.talkToGpt.sendIconColor }}
									/>
								) : (
									<SendIcon sx={{ color: theme => theme.palette.primary.talkToGpt.sendIconColor, fontSize: '18px' }} />
								)}
							</IconButton>
						</Box>
					</Box>
				</>
			) : (
				<Box sx={styles.loginMsg}>
					<Typography fontSize={24} fontWeight={400}>
						Please Sign In to talk with GPT
					</Typography>

					<Button variant="contained" onClick={() => setIsUserNameModalOpen(true)} sx={[styles.footerBtn]}>
						<Typography color={colors.black} fontSize="16px">
							Sign in
						</Typography>
					</Button>
				</Box>
			)}

			<GoogleLoginModal
				open={isUserNameModalOpen}
				onClose={() => setIsUserNameModalOpen(false)}
				userName={userName}
				setUserName={e => setUserName(e.target.value)}
				handleGoogleLogin={handleGoogleLogin}
			/>
		</Box>
	);
};

const styles = {
	container: {
		height: '100vh',
		width: '100%',
		overflow: 'auto',
		backgroundColor: theme => theme.palette.primary.talkToGpt.background
	},
	chatContainer: {
		marginBottom: 20
	},
	messageBox: {
		width: {
			xs: '80%',
			sm: '70%',
			md: '50%'
		}
	},
	messageContainer: {
		paddingY: '10px',
		borderBottomLeftRadius: 22,
		borderBottomRightRadius: 22,
		paddingX: '15px',
		boxShadow: '2px 2px 25px 1px rgba(0, 0, 0, 0.05)',
		backgroundColor: theme => theme.palette.primary.talkToGpt.messageBg
	},
	senderIcon: {
		borderRadius: 30,
		alignSelf: 'flex-start',
		width: '40px',
		height: '40px'
	},
	inputContainer: {
		position: 'fixed',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme => theme.palette.primary.talkToGpt.background,
		pb: 3,
		pt: '5px'
	},
	inputBox: {
		width: { xs: '80%', md: '90%' },
		borderRadius: '16px',
		padding: '15px',
		backgroundColor: theme => theme.palette.primary.talkToGpt.textInputBg,
		boxShadow: 20
	},
	inputBtn: {
		backgroundColor: theme => theme.palette.primary.talkToGpt.sendIconBg,
		padding: '10px',
		marginLeft: '10px',
		'&: hover': {
			backgroundColor: theme => theme.palette.primary.talkToGpt.sendIconBg
		}
	},
	renderFeedbackBtn: {
		padding: 0,
		margin: 0
	},
	loginMsg: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	copyPasteIcon: {
		color: theme => theme.palette.primary.talkToGpt.thumbUpColor,
		fontSize: '15px'
	},
	thumbUpColor: {
		color: theme => theme.palette.primary.talkToGpt.thumbUpColor,
		fontSize: '15px'
	},
	thumbDownColor: {
		color: theme => theme.palette.primary.talkToGpt.thumbDownColor,
		fontSize: '15px'
	},
	feedbackIcon: {
		marginBottom: 2,
		marginLeft: '34px',
		marginTop: 1
	},
	createdAt: {
		alignSelf: 'flex-end',
		marginRight: 2,
		marginBottom: 2,
		fontSize: '10px'
	},
	footerBtn: {
		paddingY: '10px',
		paddingX: '30px',
		minHeight: 0,
		minWidth: 0,
		textTransform: 'none',
		borderRadius: 40,
		backgroundColor: colors.white,
		'&: hover': {
			backgroundColor: colors.white
		},
		boxShadow: '0px 2px 25px -2px rgba(0, 0, 0, 0.15)',
		marginTop: 2
	}
};

export default TalkToGpt;
