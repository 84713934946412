import React, { useContext, useState, useEffect } from 'react';
import { Card, Collapse, List, ListItemButton, ListItemText, Typography } from '@mui/material';
// import { Star, StarBorder } from '@mui/icons-material';
// import pinIcon from '../../asset/images/pin_icon.png';
// import selectedPinIcon from '../../asset/images/selected_pin_icon.png';
import { UserSessionContext } from '../../context/UserSessionContext';
import { useSearchParams } from 'react-router-dom';
import colors from '../../asset/colors';

const DomainsList = ({ domainData, channels, onPressChannel }) => {
	const [searchParams] = useSearchParams();
	const queryParamDomainName = searchParams.get('domain');

	const { selectedDomain } = useContext(UserSessionContext);

	// const isUserLoggedIn = !isObjectEmpty(userData);

	const [isChannelVisible, setIsChannelVisible] = useState(true);

	useEffect(() => {
		if (queryParamDomainName && domainData.name === queryParamDomainName) {
			// This will only work when there is a domain name from query
			setIsChannelVisible(true);
		}
	}, [domainData.name, queryParamDomainName]);

	// const handleSetFavorite = async (e, channel) => {
	// 	if (e && e.stopPropagation) e.stopPropagation(); // This will prevent parent btn click when pin icon is clicked

	// 	const dataToSend = {
	// 		user_id: userData.user_id,
	// 		channel_id: channel.channel_id
	// 	};

	// 	const { success } = await createFavorite(dataToSend, userData.user_id);

	// 	if (!success) {
	// 		showInfoToast('Unable to add favorite. Please try again later');
	// 		return;
	// 	}
	// };

	// const handleDeleteFavorite = async (e, channel) => {
	// 	if (e && e.stopPropagation) e.stopPropagation(); // This will prevent parent btn click when pin icon is clicked

	// 	const { success } = await deleteFavorite(userData.user_id, channel.channel_id);

	// 	if (!success) {
	// 		showInfoToast('Unable to remove favorite. Please try again later');
	// 		return;
	// 	}
	// };

	// const handleDomainPin = async e => {
	// 	if (e && e.stopPropagation) e.stopPropagation(); // This will prevent parent btn click when pin icon is clicked

	// 	const pinnedDomains = JSON.parse(localStorage.getItem('pinnedDomains'));

	// 	let dataToPin = [];
	// 	if (pinnedDomains) {
	// 		// Check if domain is already pin. If pin remove otherwise add to dataToPin
	// 		const indexToRemove = pinnedDomains.findIndex(pinDomain => pinDomain.domain_id === domainData.domain_id);

	// 		if (indexToRemove !== -1) {
	// 			// Remove domain

	// 			const filteredPinDomains = pinnedDomains.filter(pinDomain => pinDomain.domain_id !== domainData.domain_id);

	// 			dataToPin = [...filteredPinDomains];
	// 		} else {
	// 			// Add domain
	// 			dataToPin = [...pinnedDomains, { domain_id: domainData.domain_id }];
	// 		}
	// 	} else {
	// 		dataToPin = [{ domain_id: domainData.domain_id }];
	// 	}

	// 	localStorage.setItem('pinnedDomains', JSON.stringify(dataToPin));

	// 	await getAllDomainAndChannel(userData.user_id);
	// };

	// const renderPinIcon = () => {
	// 	const pinIconStyle = {
	// 		filter: isLightTheme ? 'none' : 'invert(1)'
	// 	};

	// 	if (domainData?.is_pin) {
	// 		return <img src={selectedPinIcon} alt="Pin" height={18} width={18} style={pinIconStyle} />;
	// 	}

	// 	return <img src={pinIcon} alt="Un-Pin" height={18} width={18} style={pinIconStyle} />;
	// };

	// const renderStarIcon = is_fav => {
	// 	return is_fav ? <Star sx={styles.expandIcon} /> : <StarBorder sx={styles.expandIcon} />;
	// };

	return (
		<Card sx={{ mt: '10px', borderRadius: '10px', boxShadow: 0 }}>
			<ListItemButton
				onClick={() => setIsChannelVisible(prev => !prev)}
				sx={[
					{
						backgroundColor:
							isChannelVisible || domainData.name === selectedDomain.name
								? theme => theme.palette.primary.sideNav.selectedDomain
								: theme => theme.palette.primary.sideNav.domainBgColor,
						borderStyle: isChannelVisible ? 'solid' : 'hidden',
						borderBottomWidth: 1,
						borderColor: colors.white
					}
				]}
			>
				<ListItemText
					primaryTypographyProps={{ fontWeight: isChannelVisible ? 500 : 200, fontSize: '15px' }}
					primary={domainData && domainData?.name.replaceAll('-', ' ')}
					sx={{ ml: 0, color: theme => theme.palette.textColor }}
				/>

				{/* {isUserLoggedIn && <IconButton onClick={handleDomainPin}>{renderPinIcon()}</IconButton>} */}

				{/* {isChannelVisible ? (
					<ExpandMore sx={styles.expandIcon} />
				) : (
					<ExpandLess sx={[styles.expandIcon, { mr: domainData.name === selectedDomain.name ? '0px' : '4px' }]} />
				)} */}
			</ListItemButton>

			<Collapse in={isChannelVisible} timeout="auto">
				{channels.length === 0 ? (
					<Typography textAlign={'center'} fontSize={'13px'}>
						No channels available
					</Typography>
				) : (
					channels.map(channel => {
						const isChannelSelected = selectedDomain?.selectedChannel?.channel_id === channel.channel_id;

						const channelStyle = {
							fontWeight: isChannelSelected ? 500 : 200
							// color: theme => isChannelSelected && theme.palette.primary.sideNav.linkTextColor
							// textDecoration: isChannelSelected ? 'underline' : 'none'
						};

						return (
							<List key={channel.channel_id} component="div" disablePadding>
								<ListItemButton
									onClick={() => onPressChannel(channel)}
									sx={{
										backgroundColor: isChannelSelected
											? theme => theme.palette.primary.sideNav.selectedDomain
											: theme => theme.palette.primary.sideNav.domainBgColor
									}}
								>
									<ListItemText>
										<Typography sx={channelStyle} variant="subText">
											{channel.name}
										</Typography>
									</ListItemText>

									{/* {isUserLoggedIn && (
										<IconButton
											onClick={e => (channel.is_fav ? handleDeleteFavorite(e, channel) : handleSetFavorite(e, channel))}
										>
											{renderStarIcon(channel.is_fav)}
										</IconButton>
									)} */}
								</ListItemButton>
							</List>
						);
					})
				)}
			</Collapse>
		</Card>
	);
};

// const styles = {
// 	expandIcon: {
// 		color: theme => theme.palette.primary.sideNav.starSelectedColor,
// 		fontSize: '18px'
// 	}
// };

export default DomainsList;
