import React, { useContext, useEffect, useState } from 'react';
import {
	Drawer,
	List,
	Button,
	Typography,
	Box,
	Switch,
	Stack,
	IconButton,
	useMediaQuery,
	ButtonBase,
	Tooltip
} from '@mui/material';
import { useCookies } from 'react-cookie';
import {
	MenuOpen,
	Logout as LogoutIcon,
	Splitscreen as SplitscreenIcon,
	WebAsset as WebAssetIcon
} from '@mui/icons-material';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import colors from '../../asset/colors';
import { UserSessionContext } from '../../context/UserSessionContext';
import { ApiContext } from '../../context/ApiContext';
import { isObjectEmpty } from '../../utils/utils';
import { GoogleApiContext } from '../../context/GoogleApiContext';
import { drawerWidth } from '../../asset/constants';
import DomainsList from './DomainsList';
import MagicAiPromptModal from '../MagicAiPromptModal';
import logo from '../../asset/images/applogo.ico';
import GoogleLoginModal from '../GoogleLoginModal';
import env from '../../config/env';
import { useSearchParams } from 'react-router-dom';
import goldenPromptIcon from '../../asset/images/golden_prompt_icon.svg';

const SideNavBar = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	// eslint-disable-next-line no-unused-vars
	const [cookie, setCookie, removeCookie] = useCookies(['user']);

	const { getAllDomainAndChannel, getUserById, createUser, updateUserById } = useContext(ApiContext);
	const { getGoogleUser } = useContext(GoogleApiContext);

	const {
		isSplitViewEnabled,
		isLightTheme,
		setIsLightTheme,
		userData,
		selectedDomain,
		setisSplitViewEnabled,
		setUserData,
		setSelectedDomain,
		domains,
		showLoader
	} = useContext(UserSessionContext);

	const isScreenBig = useMediaQuery('(min-width:900px)');

	const [isUserNameModalOpen, setIsUserNameModalOpen] = useState(false);
	const [userName, setUserName] = useState('');

	// 'isDrawerOpen' state variable controls the visibility of the drawer component.
	// For screens less than 900px, it is initialized as false by default.
	// For screens greater than or equal to 900px, it is initialized as true by default.
	const [isDrawerOpen, setIsDrawerOpen] = useState(isScreenBig);
	const [openMagicAiPrompt, setOpenMagicAiPrompt] = useState(false);

	const isUserLoggedIn = !isObjectEmpty(userData);

	useEffect(() => {
		const getAllChannelList = async () => {
			await getAllDomainAndChannel(userData.user_id);
		};

		getAllChannelList();
	}, [getAllDomainAndChannel, userData.user_id]);

	const handleDrawerToggle = () => setIsDrawerOpen(prev => !prev);

	const handleSplitViewClick = () => {
		setisSplitViewEnabled(prevState => {
			if (!prevState) {
				// Only close drawer when split view is visible
				setIsDrawerOpen(false);
			}

			return !prevState;
		});
	};

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: async codeResponse => {
			setIsUserNameModalOpen(false);

			const { response: googleAuthResponse } = await getGoogleUser(codeResponse);

			// Check if user exist in BE or not
			const getUserRes = await getUserById(googleAuthResponse.id);

			if (!getUserRes.success) {
				// Create user in BE
				await createUser({
					user_id: googleAuthResponse.id,
					name: userName === '' ? googleAuthResponse.name : userName,
					email: googleAuthResponse.email,
					profile_picture_url: googleAuthResponse.picture
				});
			}

			if (getUserRes.success && userName && getUserRes.response?.name !== userName) {
				// Update the user name if entered user name does not match the user response username
				await updateUserById(googleAuthResponse.id, { name: userName });
			}

			setCookie('user', googleAuthResponse, {
				path: '/',
				expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
			});

			setUserName('');
		},
		onError: error => {
			setIsUserNameModalOpen(false);
			setUserName('');

			console.log('Login Failed:', error);
		}
	});

	const handleLogout = () => {
		googleLogout();
		removeCookie('user', { path: '/' });

		// reset state variables
		localStorage.removeItem('pinnedDomains');
		setSelectedDomain({});
		setUserData({});
		setisSplitViewEnabled(false);
		setIsUserNameModalOpen(false);
		setUserName('');
	};

	const renderDrawer = () => (
		<>
			<Box sx={styles.drawerContainer}>
				<List disablePadding>
					{/* Header */}
					<Box sx={styles.header}>
						<ButtonBase
							sx={styles.logoContainer}
							onClick={() => {
								window.location.href = env.webUrl;
							}}
						>
							<img src={logo} width={28} height={28} />

							<Typography fontWeight={700} fontSize={'24px'} ml={'10px'}>
								Turing Web
							</Typography>
						</ButtonBase>

						<Tooltip title={`Switch to ${isLightTheme ? 'Dark' : 'Light'} mode`} arrow>
							<Switch
								checked={isLightTheme}
								onChange={() => setIsLightTheme(prev => !prev)}
								sx={styles.switch}
								size="small"
							/>
						</Tooltip>

						{!isObjectEmpty(selectedDomain) && (
							<Tooltip title={isSplitViewEnabled ? 'Channel View' : 'Split View'} arrow>
								<IconButton onClick={handleSplitViewClick} disableRipple>
									{/* SplitscreenIcon will not be visible  for screen less than 900px */}
									{isSplitViewEnabled ? (
										<WebAssetIcon color="globalIcon" />
									) : (
										<SplitscreenIcon sx={styles.splitIconStyle} color="globalIcon" />
									)}
								</IconButton>
							</Tooltip>
						)}
					</Box>

					{/* Dropdown of domain and channels */}
					{domains.length === 0 ? (
						<Typography marginTop={10} fontSize={'13px'} textAlign={'center'}>
							No channels available
						</Typography>
					) : (
						domains.map(domain => {
							return (
								<DomainsList
									key={domain.domain_id}
									domainData={domain}
									channels={domain.channels}
									onPressChannel={selectedChannel => {
										setSelectedDomain({
											domain_id: domain?.domain_id,
											name: domain?.name,
											selectedChannel
										});

										// Remove URL query params when changing channels
										if (searchParams.size > 0) {
											searchParams.delete('question');
											searchParams.delete('channel');
											searchParams.delete('domain');

											setSearchParams(searchParams);
										}

										// When split view is enabled or when screen size is less than 900px then close the drawer when channel is selected
										if (isSplitViewEnabled || !isScreenBig) {
											setIsDrawerOpen(false);
										}
									}}
								/>
							);
						})
					)}
				</List>
			</Box>

			{/* Footer */}
			<Box
				sx={[styles.footerContainer, { position: 'absolute', bottom: 0, px: '10px', pb: '10px', alignItems: 'center' }]}
			>
				<Button
					sx={[{ width: '100%', marginBottom: 1, display: 'flex' }]}
					onClick={() => setOpenMagicAiPrompt(true)}
					disableRipple
				>
					<img
						src={goldenPromptIcon}
						alt="golden-prompt"
						height={20}
						width={20}
						style={{ filter: isLightTheme ? 'none' : 'invert(1)' }}
					/>

					<Typography sx={styles.faqText} variant="subText" color={theme => theme.palette.textColor}>
						Golden Prompts
					</Typography>
				</Button>

				{isUserLoggedIn ? (
					<Stack direction={'row'} alignItems="center" justifyContent="center" style={{ width: '100%' }} spacing={1}>
						<Stack direction={'row'} alignItems="center" justifyContent="center" spacing={1}>
							<img
								src={userData.profile_picture_url}
								width={30}
								height={30}
								style={{ borderRadius: 20 }}
								referrerPolicy="no-referrer"
							/>

							<Stack>
								<Typography fontWeight={500} variant="subText">
									{userData.name}
								</Typography>
								<Typography fontWeight={300} variant="subText2">
									{userData.email}
								</Typography>
							</Stack>
						</Stack>

						<IconButton onClick={handleLogout} disableRipple>
							<LogoutIcon color="globalIcon" style={{ fontSize: '20px' }} />
						</IconButton>
					</Stack>
				) : (
					<Button
						variant="contained"
						onClick={() => setIsUserNameModalOpen(true)}
						sx={[styles.footerBtn, { width: '80%' }]}
					>
						<Typography color={theme => theme.palette.textColor} fontSize="20px">
							Sign in
						</Typography>
					</Button>
				)}
			</Box>
		</>
	);

	return (
		<>
			{/* The floating MenuOpen Icon will be visible when screen size is less than 900px and when split view is enabled  */}
			<IconButton
				color="inherit"
				aria-label="open drawer"
				edge="start"
				onClick={handleDrawerToggle}
				sx={styles.menuOpenIcon}
				disableRipple
			>
				<MenuOpen />
			</IconButton>

			{/* This  drawer will be 'temporary' for the devices less than 900px and when split view is enabled  */}
			{/* NOTE: variant 'permanent' means fixed and will not be affected by the drawer toggle  */}
			{/* NOTE: variant 'temporary' means the drawer can be toggle */}
			<Drawer
				variant={isSplitViewEnabled ? 'temporary' : isScreenBig ? 'permanent' : 'temporary'}
				open={isDrawerOpen}
				onClose={handleDrawerToggle}
				ModalProps={{ keepMounted: true }}
				sx={[styles.container, { pointerEvents: showLoader ? 'none' : 'auto' }]}
				PaperProps={styles.drawerPaperProp}
				elevation={0}
			>
				{renderDrawer()}
			</Drawer>

			<GoogleLoginModal
				open={isUserNameModalOpen}
				onClose={() => setIsUserNameModalOpen(false)}
				userName={userName}
				setUserName={e => setUserName(e.target.value)}
				handleGoogleLogin={handleGoogleLogin}
			/>

			<MagicAiPromptModal open={openMagicAiPrompt} onClose={() => setOpenMagicAiPrompt(false)} />
		</>
	);
};

const styles = {
	container: {
		width: drawerWidth,
		'& .MuiDrawer-paper': {
			width: drawerWidth,
			backgroundColor: theme => theme.palette.primary.sideNav.backgroundColor
		}
	},
	drawerPaperProp: {
		style: {
			// overflowY: 'hidden',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
			borderRightWidth: 0
		}
	},
	switch: {
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: theme => theme.palette.primary.switchColor
		},
		'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
			backgroundColor: colors.gray_200
		}
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		marginY: 1,
		marginX: '10px'
	},
	footerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		marginTop: 'auto'
	},
	faqText: {
		fontWeight: 700,
		// marginBottom: 2,
		'&: hover': {
			cursor: 'pointer'
		},
		color: theme => theme.palette.primary.sideNav.linkTextColor,
		pt: '5px'
	},
	footerBtn: {
		paddingY: '5px',
		paddingX: '30px',
		minHeight: 0,
		minWidth: 0,
		textTransform: 'none',
		borderRadius: 30,
		backgroundColor: theme => theme.palette.primary.sideNav.buttonColor,
		'&: hover': {
			bgcolor: theme => theme.palette.primary.sideNav.buttonColor
		},
		boxShadow: '0px 2px 25px -2px rgba(0, 0, 0, 0.15)',
		width: '100%'
	},
	menuOpenIcon: {
		position: 'absolute',
		top: 0,
		left: 8,
		zIndex: 1
	},
	splitIconStyle: {
		display: { xs: 'none', md: 'block' },
		transform: 'rotate(90deg)'
	},
	logoContainer: { flex: 1, justifyContent: 'flex-start' },
	drawerContainer: {
		padding: '10px',
		overflow: 'auto',
		marginBottom: 12,
		'& > *+*': {
			/* hide scrollbar for IE, Edge and Firefox */
			msOverflowStyle: 'none'
		},
		'::-webkit-scrollbar': {
			/* hide scrollbar for chrome, safari and opera */
			display: 'none'
		}
	}
};

export default SideNavBar;
