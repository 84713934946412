import { Box, IconButton, List, ListItemButton, ListItemText, Modal, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import Highlighter from 'react-highlight-words';
import { useDebouncedCallback } from 'use-debounce';
import { UserSessionContext } from '../context/UserSessionContext';
import { showInfoToast } from '../utils/toastUtils';
import { isObjectEmpty } from '../utils/utils';
import colors from '../asset/colors';
import dummyPromptData from '../asset/aiPromptData';

function MagicAiPromptModal({ open, onClose }) {
	const { setSelectedAiPromptMessage, selectedDomain } = useContext(UserSessionContext);

	const [aiPrompts, setAiPrompts] = useState(dummyPromptData);
	const [searchAiText, setSearchAiText] = useState('');

	const filterPrompt = value => {
		const filteredPrompt = [];

		dummyPromptData.forEach(promptCategory => {
			if (promptCategory.category.includes(value) || promptCategory.prompts.some(prompt => prompt.includes(value))) {
				filteredPrompt.push(promptCategory);
			}
		});

		setAiPrompts(filteredPrompt);
	};

	const debouncedHandleTextChange = useDebouncedCallback(value => {
		filterPrompt(value);
	}, 1000);

	const handlePromptClick = promptMsg => {
		if (isObjectEmpty(selectedDomain)) {
			showInfoToast('Please select a channel to from the side bar.', 4000);
			return;
		}
		setSelectedAiPromptMessage(promptMsg);
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box sx={styles.container}>
				<Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
					<IconButton onClick={onClose}>
						<CancelIcon color="globalIcon" size={20} />
					</IconButton>
				</Box>

				<Box sx={styles.inputContainer}>
					<TextField
						value={searchAiText}
						label="Search AI Prompts"
						variant="outlined"
						type="text"
						fullWidth
						onChange={event => {
							setSearchAiText(event.target.value);
							debouncedHandleTextChange(event.target.value);
						}}
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={() => {
										setAiPrompts(dummyPromptData);
										setSearchAiText('');
									}}
									sx={{ visibility: searchAiText.length > 0 ? 'visible' : 'hidden' }}
								>
									<ClearIcon />
								</IconButton>
							)
						}}
					/>
				</Box>

				<List sx={{ zIndex: -1 }}>
					{aiPrompts.map((promptCategory, index) => {
						return (
							<Box key={index}>
								<Typography sx={styles.categoryHeading}>
									<Highlighter
										searchWords={[searchAiText]}
										autoEscape={true}
										textToHighlight={promptCategory.category}
									/>
								</Typography>

								{promptCategory?.prompts.map((prompt, promptIndex) => {
									return (
										<ListItemButton key={promptIndex} divider onClick={() => handlePromptClick(prompt)}>
											<ListItemText sx={{ mx: 3 }}>
												<Highlighter searchWords={[searchAiText]} autoEscape={true} textToHighlight={prompt} />
											</ListItemText>
										</ListItemButton>
									);
								})}
							</Box>
						);
					})}
				</List>
			</Box>
		</Modal>
	);
}

const styles = {
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'scroll',
		width: { xs: '90%', md: '70%' }, // screen less than 900px will have modal width 90%
		height: '70%',
		bgcolor: 'background.paper',
		boxShadow: 24,
		borderRadius: 5
	},
	inputContainer: {
		mx: 3,
		mt: 1,
		position: 'sticky',
		top: 0,
		backgroundColor: colors.white,
		pt: 2
	},
	categoryHeading: {
		mx: 4,
		fontWeight: 800,
		mt: 1,
		fontStyle: 'italic',
		fontSize: 20,
		color: colors.lightRed
	}
};

export default MagicAiPromptModal;
