import '@sendbird/uikit-react/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Routes from './Routes';
import UserSessionContextProvider, { UserSessionContext } from './context/UserSessionContext';
import ApiContextProvider, { ApiContext } from './context/ApiContext';
import { LoaderHoc } from './components';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from './config/theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider, useCookies } from 'react-cookie';
import GoogleApiContextProvider from './context/GoogleApiContext';
import env from './config/env';
import ErrorFallback from './components/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

const errorBoundaryHandler = (error, info) => {
	// TODO: send error
};

function AppWrap() {
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback} onError={errorBoundaryHandler}>
			<GoogleOAuthProvider clientId={env.googleClientId}>
				<CookiesProvider>
					<UserSessionContextProvider>
						<GoogleApiContextProvider>
							<ApiContextProvider>
								<LoaderHoc>
									<App />
									<ToastContainer />
								</LoaderHoc>
							</ApiContextProvider>
						</GoogleApiContextProvider>
					</UserSessionContextProvider>
				</CookiesProvider>
			</GoogleOAuthProvider>
		</ErrorBoundary>
	);
}

const App = () => {
	const isSmallerScreen = useMediaQuery('(max-width:600px)');

	const { isLightTheme } = useContext(UserSessionContext);
	const { getUserById } = useContext(ApiContext);
	const [cookies] = useCookies(['user']);

	useEffect(() => {
		if (cookies.user) {
			(async () => {
				await getUserById(cookies.user.id);
			})();
		}
	}, [cookies.user, getUserById]);

	useEffect(() => {
		if (isSmallerScreen) {
			// This will disable the zoom in ios devices
			const disableZoom = e => {
				e.preventDefault();
			};

			document.addEventListener('gesturestart', disableZoom);

			return () => {
				document.removeEventListener('gesturestart', disableZoom);
			};
		}
	}, [isSmallerScreen]);

	return (
		<ThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
			<CssBaseline />
			<Routes />
		</ThemeProvider>
	);
};

export default AppWrap;
