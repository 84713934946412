import React, { useContext } from 'react';
import CustomLoader from './CustomLoader';
import { UserSessionContext } from '../context/UserSessionContext';

function LoaderHoc({ children }) {
	const { showLoader } = useContext(UserSessionContext);

	return (
		<>
			{children}
			{showLoader && <CustomLoader />}
		</>
	);
}

export default LoaderHoc;
