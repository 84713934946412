import { OpenChannel, SendBirdProvider } from '@sendbird/uikit-react';
import React, { useContext } from 'react';
import { UserSessionContext } from '../../context/UserSessionContext';
import { Box, useMediaQuery } from '@mui/material';
import colors from '../../asset/colors';
import env from '../../config/env';

const HumanChats = () => {
	const isSmallerScreen = useMediaQuery('(max-width:600px)');

	const { selectedDomain, userData, isLightTheme } = useContext(UserSessionContext);

	return (
		<Box sx={[styles.container, { paddingBottom: isSmallerScreen ? 6 : 3 }]}>
			<SendBirdProvider
				appId={env.sendBirdAppId}
				userId={userData.user_id ?? env.defaultUserId}
				nickname={userData.name ?? env.defaultUserName}
				profileUrl={userData.profile_picture_url ?? ''}
				theme={isLightTheme ? 'light' : 'dark'}
			>
				<OpenChannel channelUrl={selectedDomain?.selectedChannel?.sendbird_channel_id ?? ''} />
			</SendBirdProvider>
		</Box>
	);
};

const styles = {
	container: {
		width: '100%',
		height: '100vh',
		backgroundColor: colors.white
	}
};

export default HumanChats;
