import React, { useCallback, useContext, useEffect } from 'react';
import { ChatRoom, SideNavBar } from '../components';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { UserSessionContext } from '../context/UserSessionContext';
import defaultHomeImg from '../asset/images/home_page_default_img.png';
import { isObjectEmpty } from '../utils/utils';
import { drawerWidth } from '../asset/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiContext } from '../context/ApiContext';
import env from '../config/env';

function Home() {
	const isSmallerScreen = useMediaQuery('(max-width:600px)');

	const navigate = useNavigate();

	const { selectedDomain, setSelectedDomain, isSplitViewEnabled } = useContext(UserSessionContext);
	const { getChannelById } = useContext(ApiContext);

	const [searchParams] = useSearchParams();
	const channelId = searchParams.get('channel');
	const domainName = searchParams.get('domain');

	const getChannel = useCallback(async () => {
		const channelIdToSend = channelId || env.defaultChannelId;
		const domainNameToSend = domainName || env.defaultDomainName;

		const { success, errorMsg, response } = await getChannelById(domainNameToSend, channelIdToSend);

		if (!success) {
			console.log('Something went wrong - ', errorMsg);
			navigate('/app');
			return;
		}

		setSelectedDomain({ name: domainNameToSend, selectedChannel: response });
	}, [channelId, domainName, getChannelById, navigate, setSelectedDomain]);

	// This useEffect will fetch the query params from URL http://localhost:3000/app/?domain=DOMAIN_NAME?channel=CHANNEL_ID&question=Tell+me+about+IIT+Jee
	useEffect(() => {
		getChannel();
	}, [getChannel]);

	return (
		// This margin left will only be visible for screen more than 900px
		// And the 'display' style will only be used when we are using splitView because of the split view drawer
		<Box
			sx={[styles.container, isSplitViewEnabled ? { display: 'flex' } : { ml: { md: drawerWidth } }]}
			component={'main'}
		>
			<SideNavBar />

			{isObjectEmpty(selectedDomain) ? (
				<Box component={'div'} sx={styles.defaultHomeContainer}>
					<img
						src={defaultHomeImg}
						alt="home screen image"
						height={isSmallerScreen ? 300 : 500}
						width={isSmallerScreen ? 300 : 500}
					/>

					<Typography fontSize="24px" mt={5} mx={2} textAlign="center" fontWeight={400}>
						Please select a channel from the side bar
					</Typography>
				</Box>
			) : (
				<ChatRoom />
			)}
		</Box>
	);
}

const styles = {
	container: {
		height: '100vh',
		overflowX: 'hidden',
		overflowY: 'hidden'
	},
	defaultHomeContainer: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme => theme.palette.primary.home.backgroundColor,
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	}
};

export default Home;
