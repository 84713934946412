import { createContext, useCallback, useContext } from 'react';
import axios from 'axios';
import { UserSessionContext } from './UserSessionContext';
import env from '../config/env';

const AxiosService = axios.create({
	baseURL: env.apiUrl
});

export const ApiContext = createContext();

const ApiContextProvider = ({ children }) => {
	const { setShowLoader, setUserData, setDomains } = useContext(UserSessionContext);

	const get = useCallback(
		async (endpoint, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.get(endpoint)
				.then(res => {
					if (res.data.response_status !== 200) {
						console.log(`🛑 GET - ${endpoint} err`, res.data.description);

						return { success: false, errorMsg: res.data.description, response: {} };
					}

					return { success: true, errorMsg: '', response: res.data.response_data };
				})
				.catch(err => {
					console.log(`🛑 GET - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => {
					setShowLoader(false);
				});
		},
		[setShowLoader]
	);

	const post = useCallback(
		async (endpoint, data, showLoader = true, headers) => {
			setShowLoader(showLoader);
			return AxiosService.post(endpoint, data, headers)
				.then(res => {
					if (res.data.response_status !== 200) {
						console.log(`🛑 POST: ${endpoint} err`, res.data.description);

						return { success: false, errorMsg: res.data.description, response: {} };
					}

					return { success: true, errorMsg: '', response: res.data.response_data };
				})
				.catch(err => {
					console.log(`🛑 POST: ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	const put = useCallback(
		async (endpoint, data) => {
			setShowLoader(true);

			return AxiosService.put(endpoint, data)
				.then(res => {
					if (res.data.response_status !== 200) {
						console.log(`🛑PUT: ${endpoint} err`, res.data.description);

						return { success: false, errorMsg: res.data.description, response: {} };
					}

					return { success: true, errorMsg: '', response: res.data.response_data };
				})
				.catch(err => {
					console.log(`🛑PUT - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	const deleteMe = useCallback(
		async endpoint => {
			setShowLoader(true);

			return AxiosService.delete(endpoint)
				.then(res => {
					if (res?.data?.response_status !== 200) {
						console.log(`🛑DELETE: ${endpoint} err`, res?.data?.description);

						return { success: false, errorMsg: res?.data?.description, response: {} };
					}

					return { success: true, errorMsg: '', response: res?.data?.response_data };
				})
				.catch(err => {
					console.log(`🛑DELETE - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	// User Endpoints
	const createUser = useCallback(
		async data => {
			const postRes = await post(`/user/`, data);

			if (postRes.success) setUserData(postRes.response);

			return postRes;
		},
		[post, setUserData]
	);

	const getUserById = useCallback(
		async id => {
			const getRes = await get(`/user/${id}/`);

			if (getRes.success) setUserData(getRes.response);

			return getRes;
		},
		[get, setUserData]
	);

	const updateUserById = useCallback(
		async (id, dataToUpdate) => {
			const updateRes = await put(`/user/${id}/`, dataToUpdate);

			if (updateRes.success) setUserData(updateRes.response);

			return updateRes;
		},
		[put, setUserData]
	);

	// Channel endpoints
	const getAllChannelData = useCallback(
		async domainName => {
			const channelRes = await get(`/domain/${domainName}/channel/`);

			return channelRes;
		},
		[get]
	);

	const getChannelById = useCallback(
		async (domainName, channelId) => {
			const channelRes = await get(`/domain/${domainName}/channel/${channelId}/`);

			return channelRes;
		},
		[get]
	);

	// Ai Endpoints
	const sendMessageToBard = useCallback(
		async message => {
			const postRes = await post(`/ai_bot/`, message, false);

			return postRes;
		},
		[post]
	);

	// Messages endpoints
	const updateMessage = useCallback(
		async (messageId, dataToUpdate) => {
			const putRes = await put(`/message/${messageId}/`, dataToUpdate);

			return putRes;
		},
		[put]
	);

	const getAllMessages = useCallback(
		async (channelId, userId) => {
			const getRes = await get(`/message/?channel_id=${channelId}&user_id=${userId}`);

			return getRes;
		},
		[get]
	);

	const deleteUserMessages = useCallback(
		async (userId, channelId) => {
			const res = deleteMe(`/message/?user_id=${userId}&channel_id=${channelId}`);

			return res;
		},
		[deleteMe]
	);

	const getAllDomainAndChannel = useCallback(
		async userId => {
			const domainRes = await get('/domain/');
			let dataToSend = [];

			const pinnedDomains = JSON.parse(localStorage.getItem('pinnedDomains'));

			if (domainRes.success) {
				const channelsRes = await get(`/channel/?user_id=${userId}`);

				domainRes.response.map(domain => {
					let is_pin = false;

					// Check if there are any pin domains
					if (pinnedDomains && pinnedDomains.some(pinDomain => pinDomain.domain_id === domain.domain_id)) {
						is_pin = true;
					}

					if (channelsRes.success) {
						const channels = channelsRes.response.filter(channel => channel.domain_name === domain.name);

						// Sort channels in alphabetical order
						channels.sort((a, b) => {
							// ignore upper and lowercase
							const nameA = a.name.toUpperCase();
							const nameB = b.name.toUpperCase();

							if (nameA > nameB) {
								return 1;
							}
							if (nameA < nameB) {
								return -1;
							}

							// names must be equal
							return 0;
						});

						// Sort channels by favorite
						channels.sort((a, b) => b.is_fav - a.is_fav);

						dataToSend.push({ ...domain, is_pin, channels });
					}
				});

				// Sort domain by pin
				dataToSend.sort((a, b) => b.is_pin - a.is_pin);

				setDomains(dataToSend);
			}

			return dataToSend;
		},
		[get, setDomains]
	);

	// Favorite endpoints
	const createFavorite = useCallback(
		async (dataToPost, userId) => {
			const res = await post('/favourite/', dataToPost);

			if (res.success) getAllDomainAndChannel(userId);

			return res;
		},
		[getAllDomainAndChannel, post]
	);

	const deleteFavorite = useCallback(
		async (userId, channelId) => {
			const res = await deleteMe(`/favourite/?channel_id=${channelId}&user_id=${userId}`);

			if (res.success) getAllDomainAndChannel(userId);

			return res;
		},
		[deleteMe, getAllDomainAndChannel]
	);

	return (
		<ApiContext.Provider
			value={{
				getAllChannelData,
				createUser,
				getUserById,
				sendMessageToBard,
				updateMessage,
				getAllMessages,
				updateUserById,
				getChannelById,
				getAllDomainAndChannel,
				createFavorite,
				deleteFavorite,
				deleteUserMessages
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiContextProvider;
