import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const CustomLoader = () => {
	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default CustomLoader;
