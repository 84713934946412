import { createContext, useState } from 'react';

export const UserSessionContext = createContext();

const UserSessionContextProvider = ({ children }) => {
	const [isLightTheme, setIsLightTheme] = useState(true);
	const [isSplitViewEnabled, setisSplitViewEnabled] = useState(false);

	const [showLoader, setShowLoader] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState(null);

	/* selectedDomain = { ...DOMAIN_DATA, selectedChannel: {...channelData} } */
	const [selectedDomain, setSelectedDomain] = useState({});
	const [userData, setUserData] = useState({});
	const [domains, setDomains] = useState([]);
	const [selectedAiPromptMessage, setSelectedAiPromptMessage] = useState();

	return (
		<UserSessionContext.Provider
			value={{
				showLoader,
				setShowLoader,
				authenticatedUser,
				setAuthenticatedUser,
				selectedDomain,
				setSelectedDomain,
				userData,
				setUserData,
				isLightTheme,
				setIsLightTheme,
				isSplitViewEnabled,
				setisSplitViewEnabled,
				domains,
				setDomains,
				selectedAiPromptMessage,
				setSelectedAiPromptMessage
			}}
		>
			{children}
		</UserSessionContext.Provider>
	);
};

export default UserSessionContextProvider;
