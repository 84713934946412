import { Box, ButtonBase, Divider, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { navButtonText, navButtons } from '../../asset/constants';
import HumanChats from './HumanChats';
import TalkToGpt from './TalkToGpt';
import { UserSessionContext } from '../../context/UserSessionContext';
import { ApiContext } from '../../context/ApiContext';
import { showErrorToast, showInfoToast } from '../../utils/toastUtils';
import { v4 as uuidv4 } from 'uuid';
import { isObjectEmpty } from '../../utils/utils';
import crossIcon from '../../asset/images/cross_icon.png';
import ConfirmDialog from '../ConfirmDialog';

const dummyChatData = [
	{
		id: uuidv4(),
		sender: 'GPT',
		message: "I'm ready to answer you questions, please start asking!"
	}
];

const ChatRoom = () => {
	const isSmallerScreen = useMediaQuery('(max-width:600px)');

	const muiTheme = useTheme();

	const { isSplitViewEnabled, selectedDomain, userData, setShowLoader, isLightTheme } = useContext(UserSessionContext);
	const { deleteUserMessages, getAllMessages } = useContext(ApiContext);

	const isUserLoggedIn = !isObjectEmpty(userData);

	const [selectedBtn, setSelectedBtn] = useState(navButtonText.talkToGtp);
	const [messages, setMessages] = useState([]);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const getAllGPTMessages = useCallback(async () => {
		const { response, success } = await getAllMessages(selectedDomain?.selectedChannel?.channel_id, userData.user_id);

		let filterMsg = [];

		if (success) {
			response.map(msg => {
				filterMsg.push({ id: uuidv4(), sender: 'user', message: msg.question, created_at: msg.created_at });
				filterMsg.push({ id: msg.id, sender: 'GPT', message: msg.answer, ...msg });
			});

			setMessages([...dummyChatData, ...filterMsg]);
		}
	}, [getAllMessages, selectedDomain?.selectedChannel?.channel_id, userData.user_id]);

	useEffect(() => {
		if (selectedBtn === navButtonText.humanChats) {
			setShowLoader(true);
			setTimeout(() => {
				setShowLoader(false);
			}, 2000);
		}
	}, [selectedBtn, selectedDomain, setShowLoader]);

	useEffect(() => {
		if (userData.user_id && navButtonText.talkToGtp === selectedBtn) {
			getAllGPTMessages();
		}
	}, [getAllGPTMessages, selectedBtn, userData.user_id]);

	const displaySelectedComponent = () => {
		switch (selectedBtn) {
			case navButtonText.talkToGtp: {
				return <TalkToGpt messages={messages} setMessages={setMessages} getAllGPTMessages={getAllGPTMessages} />;
			}

			case navButtonText.humanChats: {
				return <HumanChats />;
			}

			default: {
				break;
			}
		}
	};

	const handleDeleteUserMessages = async () => {
		const { success, errorMsg } = await deleteUserMessages(userData.user_id, selectedDomain.selectedChannel.channel_id);

		if (!success) {
			console.log('errorMsg:', errorMsg);
			showInfoToast('Something went wrong please try again later');
			return;
		}

		setMessages(dummyChatData);
	};

	const displayTopNav = topNavBtn => {
		let btnBackgroundColor =
			selectedBtn === topNavBtn.name
				? muiTheme.palette.primary.talkToGpt.selectedNavColor
				: muiTheme.palette.primary.talkToGpt.defaultNavColor;

		let fontWeight = selectedBtn === topNavBtn.name ? 600 : 400;

		if (isSplitViewEnabled) {
			btnBackgroundColor = muiTheme.palette.primary.talkToGpt.selectedNavColor;
			fontWeight = 600;
		}

		const navBtnStyle = {
			backgroundColor: btnBackgroundColor,
			'&: hover': {
				backgroundColor:
					!isSplitViewEnabled &&
					selectedBtn !== topNavBtn.name &&
					muiTheme.palette.primary.talkToGpt.defaultNavHoverColor
			},
			borderTopRightRadius: isSplitViewEnabled ? 0 : { xs: 0, md: 22 },
			borderTopLeftRadius: isSplitViewEnabled ? 0 : { xs: 0, md: 22 }
		};

		return (
			<Box sx={[styles.flex, navBtnStyle]} key={topNavBtn.id}>
				<ButtonBase
					disabled={isSplitViewEnabled || selectedBtn === topNavBtn.name}
					sx={[
						styles.flex,
						styles.btn,
						{ fontWeight, paddingLeft: navButtonText.talkToGtp === topNavBtn.name && isSmallerScreen ? 3 : 0 }
					]}
					onClick={() => setSelectedBtn(topNavBtn.name)}
					disableRipple
				>
					{navButtonText.talkToGtp === topNavBtn.name
						? `${selectedDomain.selectedChannel.name} ${topNavBtn.name}`
						: topNavBtn.name}
				</ButtonBase>

				{isUserLoggedIn && navButtonText.talkToGtp === topNavBtn.name && (
					<Tooltip title="Clear all messages" arrow>
						<IconButton
							// Disable button when not on talkToGptScreen and when split view is enabled
							disabled={!isSplitViewEnabled && selectedBtn !== topNavBtn.name}
							onClick={() => {
								if (messages && messages.length === 1) {
									showErrorToast('There are no messages to delete', 4000);
									return;
								}

								setShowConfirmationModal(prev => !prev);
							}}
						>
							<img
								src={crossIcon}
								alt="delete-all"
								height={18}
								width={18}
								style={{ filter: isLightTheme ? 'none' : 'invert(1)' }}
							/>
						</IconButton>
					</Tooltip>
				)}
			</Box>
		);
	};

	return (
		<Box sx={styles.container}>
			<Stack
				direction="row"
				divider={isSplitViewEnabled && <Divider orientation="vertical" flexItem />}
				marginTop={isSplitViewEnabled ? 0 : { xs: 0, md: 1 }}
			>
				{navButtons.map(btn => displayTopNav(btn))}
			</Stack>

			{isSplitViewEnabled ? (
				<Stack direction={'row'} divider={<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 1 }} />}>
					<TalkToGpt messages={messages} setMessages={setMessages} getAllGPTMessages={getAllGPTMessages} />

					<HumanChats />
				</Stack>
			) : (
				displaySelectedComponent()
			)}

			<ConfirmDialog
				open={showConfirmationModal}
				onClose={() => setShowConfirmationModal(prev => !prev)}
				message="Are you sure you want to delete all messages?"
				handleDeleteBtnPress={handleDeleteUserMessages}
			/>
		</Box>
	);
};

const styles = {
	container: {
		width: '100%',
		height: '100vh'
	},
	btn: {
		color: theme => theme.palette.textColor,
		borderRadius: 0,
		textTransform: 'none',
		paddingY: '8px',
		'&:disabled': {
			color: theme => theme.palette.textColor
		}
	},
	flex: {
		display: 'flex',
		flex: 1
	}
};

export default ChatRoom;
