import { createTheme } from '@mui/material';
import colors from '../asset/colors';

export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		globalIcon: {
			main: colors.black
		},
		primary: {
			main: '#3f51b5',
			switchColor: colors.switchColor.light,
			home: {
				backgroundColor: colors.homeBg.light
			},
			sideNav: {
				buttonColor: colors.white,
				background: colors.white,
				textColor: colors.black,
				backgroundColor: '#EEF0F2',
				domainBgColor: colors.white,
				selectedDomain: colors.selectedDomainBg.light,
				selectedDomainBorderColor: colors.selectedDomainBorderColor,
				linkTextColor: colors.black,
				starSelectedColor: '#2F363D'
			},
			talkToGpt: {
				aiBgColor: '#F7F7F8',
				feedbackBtnColor: '#E9DCC9',
				background: '#EEF0F2',
				messageBg: colors.white,
				thumbUpColor: colors.thumbUp.light,
				thumbDownColor: colors.thumbDown,
				textInputBg: colors.white,
				textInputBorder: colors.textInputBorderColor.light,
				sendIconColor: colors.msgSendIcon.light,
				sendIconBg: '#2F363D',
				selectedNavColor: '#EEF0F2',
				selectedNavHoverColor: '#d7daf5',
				defaultNavColor: '#E5E7E9',
				defaultNavHoverColor: '#cacaca',
				createdAtColor: colors.createdAtColor
			}
		},
		secondary: {
			main: '#f50057'
		},
		background: {
			default: '#F5F5F5'
		},
		textColor: colors.textColor.light
	},
	typography: {
		fontFamily: ['Inter', 'sans-serif'],
		body2: {
			fontSize: '15px',
			textTransform: 'capitalize'
		},
		subText: {
			fontSize: '13px',
			textTransform: 'capitalize'
		},
		subText2: {
			fontSize: '10px'
		},
		subtitle1: {
			fontSize: '13px'
		}
	}
});

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		globalIcon: {
			main: colors.white
		},
		primary: {
			main: '#3f51b5',
			switchColor: colors.switchColor.dark,
			home: {
				backgroundColor: colors.homeBg.dark
			},
			sideNav: {
				buttonColor: colors.borderColor,
				background: colors.black,
				textColor: colors.white,
				backgroundColor: '#282828',
				domainBgColor: '#2F363D',
				selectedDomain: colors.selectedDomainBg.dark,
				selectedDomainBorderColor: colors.white,
				linkTextColor: colors.white,
				starSelectedColor: '#F6F8FA'
			},
			talkToGpt: {
				aiBgColor: '#2e3033',
				feedbackBtnColor: colors.white,
				background: colors.talkToGpt.dark,
				messageBg: colors.black,
				thumbUpColor: colors.thumbUp.dark,
				thumbDownColor: colors.thumbDown,
				textInputBg: colors.black,
				textInputBorder: colors.textInputBorderColor.dark,
				sendIconColor: colors.msgSendIcon.dark,
				sendIconBg: '#F7F7F8',
				selectedNavColor: colors.primary,
				selectedNavHoverColor: '#282828',
				defaultNavColor: '#444444',
				defaultNavHoverColor: '#565656',
				createdAtColor: colors.white
			}
		},
		secondary: {
			main: '#f50057'
		},
		background: {
			default: colors.primary
		},
		textColor: colors.textColor.dark
	},
	typography: {
		fontFamily: ['Inter', 'sans-serif'],
		body2: {
			fontSize: '15px',
			textTransform: 'capitalize'
		},
		subText: {
			fontSize: '13px',
			textTransform: 'capitalize'
		},
		subText2: {
			fontSize: '10px'
		},
		subtitle1: {
			fontSize: '13px'
		}
	}
});
